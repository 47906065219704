const ENV = process.env.ENVIRONMENT || process.env.NODE_ENV;

const REGION = process.env.GATSBY_REGION;

if (!REGION) throw new Error('Required REGION (process.env.GATSBY_REGION) is not set');

module.exports = {
  API_HOST: process.env.GATSBY_API_HOST,
  COOKIE_CONSENT_NAME: 'CookieControl',
  IS_PROD: ENV === 'production',
  REGION: (process.env.GATSBY_REGION || '').toUpperCase(),
  STRIPE_PUBLISHABLE_KEY: process.env.GATSBY_STRIPE_PUBLISHABLE_KEY,
};
