import { PAYMENT_TYPES } from '../constants/index';

const { API_HOST } = require('../../config');

const extendOptions = (opt, skipContentType = false) => ({
    mode: 'cors', // no-cors, *cors, same-origin
    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
    credentials: 'omit',
    ...(skipContentType ?
        {} :
        {
            headers: {
                'Content-Type': 'application/json',
            },
        }),
    // redirect: 'follow', // manual, *follow, error
    // referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
    ...opt,
});

const commonDataResponse = async(res) => {
    const { status, headers } = res;
    const contentType = headers.get('Content-Type') || '';
    const isJson = contentType.startsWith('application/json');

    if (status >= 400) {
        let err;

        if (isJson) {
            const errJson = await res.json();
            const errorMessage = errJson.message ? errJson.message : errJson.error.message;
            err = new Error(errorMessage);

            if (errJson.errors) {
                err.fields = errJson.errors.reduce(
                    (fields, { field, error }) => ({
                        ...fields,
                        [field]: error,
                    }), {}
                );
            }
        } else {
            const errText = await res.text();
            err = new Error(errText);
            err.fields = {};
        }
        err.statusCode = res.status;

        throw err;
    } else if (status === 204) {
        return null;
    }

    return isJson ? res.json() : res.text();
};
const toUrl = (path) => `${path.startsWith('/') ? API_HOST : ''}${path}`;

async function del(path) {
    return fetch(toUrl(path), extendOptions({ method: 'DELETE' }, true)).then(commonDataResponse);
}
async function get(path, skipContentType) {
    return fetch(toUrl(path), extendOptions({ method: 'GET' }, skipContentType)).then(
        commonDataResponse
    );
}
async function post(path, data = {}) {
    return fetch(toUrl(path), extendOptions({ method: 'POST', body: JSON.stringify(data) })).then(
        commonDataResponse
    );
}
async function put(path, data = {}) {
    return fetch(toUrl(path), extendOptions({ method: 'PUT', body: JSON.stringify(data) })).then(
        commonDataResponse
    );
}

function toObject(data) {
    if (typeof data === 'string') {
        try {
            return JSON.parse(data);
        } catch (_) {}
    }
    return data;
}

export const requestPasswordReset = (email) => get(`/v2/web/resetPassword/${email}`, true);
export const resetPassword = (password, token) =>
    post(`/v2/web/resetPassword`, { password, token });
export const login = async(data) => {
    const { _id: sessionId } = await post(`/v2/web/login`, data);
    const userData = await me(sessionId);
    return {
        sessionId,
        userData,
    };
};
export const loginWithLoginToken = (email, password) => {
    return post('/v2/web/lt/login', { email, password }).then(toObject);
}

export const createStripeSubscription = (paymentMethodId, sessionId) =>
    post(`/v2/subscriptions/stripe?sessionId=${sessionId}`, { paymentMethodId });

export const stripeSubscriptionComplete = (sessionId) =>
    put(`/v2/users/stripe?sessionId=${sessionId}`, {});

export const loginWithToken = (token) =>
    post('/v2/web/tokenLogin', { loginToken: token }).then(toObject);

export const createSessionIdFromLoginToken = (loginToken) => {
    return post('/v2/web/lt/session', { loginToken }).then(toObject);
}

// TODO update to new endpoint when available
export const createLoginTokenFromSession = (sessionId) =>
    post(`/v2/web/lt/loginToken?sessionId=${sessionId}`).then(toObject);

export const logout = (sessionId) => post(`/v2/web/logout?sessionId=${sessionId}`);
export const me = (sessionId) => get(`/v2/web/me?sessionId=${sessionId}`);
export const getPaymentInfo = (userId, sessionId) =>
  get(`/v2/web/users/${userId}/subscription?sessionId=${sessionId}`);
export const getSubscriptionInfo = (sessionId, userId) => {
  return get(`/v3/subscription?sessionId=${sessionId}&userId=${userId}`)
    .catch(() => {
        return null;
    });

}
export const cancelSubscription = async(userId, reason, sessionId, paymentType) => {
    if (paymentType === PAYMENT_TYPES.STRIPE) {
        const res = await del(`/v2/subscriptions/stripe?sessionId=${sessionId}`);
        await sendUserReason(reason, sessionId);
        return res;
    } else if (paymentType === PAYMENT_TYPES.KLARNA) {
        const res = await del(`/v2/subscriptions/klarna?sessionId=${sessionId}`);
        await sendUserReason(reason, sessionId);
        return res;
    } else if (paymentType === PAYMENT_TYPES.MONDIDO) {
        const res = await del(`/v2/web/users/${userId}/subscription?sessionId=${sessionId}`);
        await sendUserReason(reason, sessionId);
        return res;
    }
};
export const sendUserReason = async(reason, sessionId) =>
    post(`/v2/web/analytics?sessionId=${sessionId}`, [{ name: 'cancellation_reason', parameters: {reason} }]);
export const sendUserFeedback = async(parameters, sessionId) =>
    post(`/v2/web/analytics?sessionId=${sessionId}`, [{ name: 'cancellation_feedback', parameters: parameters }]);

export const updateEmail = (email, sessionId) =>
    put(`/v2/web/email?sessionId=${sessionId}`, { email });
export const updatePassword = (oldP, newP, sessionId) =>
    put(`/v2/web/password?sessionId=${sessionId}`, { password: { old: oldP, new: newP } });

export const postTrackingEvent = (data, sessionId) => {
    const parsedBody = data.map((item) => ({
        ...item,
        parameters: Object.entries(item.parameters).reduce(
          (obj, [k, v]) => ({
              ...obj,
              [k]: Array.isArray(v)
                ? v.map((o) => (o && typeof o === 'object' ? JSON.stringify(o) : o))
                : v,
          }),
          {}
        ),
    }));
    return post(`/v2/web/analytics` + (sessionId ? `?sessionId=${sessionId}` : ''), parsedBody);
}

export const getSubscription = (loginToken) => {
    return get(`/v3/mypages/subscription/logintoken?loginToken=${loginToken}&region=SWE`)
      .catch(() => {
          return '';
      });
}

export const updateSubscription = (sessionId, data) =>
  put(`/v3/mypages/subscription?sessionId=${sessionId}`, data);
